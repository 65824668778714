import moment from "moment";
import { useState } from "react";
import { List } from "reactstrap";
import { CalendarShiftDTO } from "../interface/interface";
import conflict from "./conflics";
import CalendarShiftModal from "./modals/ShiftModal";
import authService from "../services/auth.service";

interface Iprops {
    event: CalendarShiftDTO;
    refresh: Function;
}

export default function EventComponent(props: Iprops) {
    const [eventInfoModal, setEventInfoModal] = useState(false);

    // get is user isAuth
    const isAuth = authService.isAuth();

    let blanks = [];
    const emptySlots = props.event.slots! - props.event.members.length;

    // if slots is 0
    if (props.event.slots === 0) {
        blanks.push("");
    }
    else {
        for (let d = 1; d <= (emptySlots); d++) {

            blanks.push("");
        }
    }


    const mapblanks = blanks.map((Member, index) => {

        return (
            <li key={index}>
                {Member}
            </li>
        );
    });

    const mapMembers = props.event.members.map((Member, index) => {
        return (
            <li key={index}>
                {Member.name}
            </li>
        );
    });

    const eventBoxColor = () => {
        if (props.event.myShift) return "event-myshift";
        return "";
    };

    const eventBoxBorderColor = () => {
        if (props.event.shiftConflict != null && conflict(props.event.shiftConflict!) !== "") return "event-conflict";
        return "event-box";
    };

    const handleClose = () => {
        setEventInfoModal(false);
        //props.refresh();
    };

    const Test = () => {
        props.refresh();
    };

    const renderModal = () => {
        return (
            <div>
                <CalendarShiftModal
                    event={props.event}
                    isOpen={eventInfoModal}
                    returnUrl={""}
                    refetch={Test}
                    close={handleClose}
                />
            </div>
        );
    };

    const renderHelperText = () => {
        let conflictText = conflict(props.event.shiftConflict!);
        const myShift = props.event.myShift;

        if ((conflictText === "" || myShift) && !(props.event.slots === 0)) {
            if (emptySlots === 1) {
                conflictText = emptySlots + "/" + props.event.slots + " ledig plads";
            } else {
                conflictText = emptySlots + "/" + props.event.slots + " ledige pladser";
            }
            //conflictText += " af " + props.event.slots;
        }

        return (
            <div className="shiftprogresstext">
                {conflictText}
            </div>
        );
    };
    // const ifWeekend = (moment(props.event.startTime).day() === 0) || (moment(props.event.startTime).day() === 6);


    const renderMembers = () => {
        // if isAuth
        if (isAuth) {
            return (
                <List>
                    {mapMembers}
                    {mapblanks}
                </List>
            );
        }
        else {
            return (
                <List>
                    <li>
                        Ledig Vagt
                    </li>
                </List>
            );
        }
    };


    return (
        <div>
            {renderModal()}
            <div key={props.event.shiftId} className="row event-row">
                <div className="col">
                    <div className={eventBoxBorderColor() + " " + eventBoxColor()} onClick={() => setEventInfoModal(true)}>
                        <div className="event-link active">
                            {props.event.allDay ? "" : (
                                <div>

                                    <span className="font-weight-bold"><b>{moment(props.event.startTime).format("HH:mm")} - {moment(props.event.endTime).format("HH:mm")}</b> ({props.event.units.toFixed(1)}t) </span>

                                    {/* <Badge className="" color="info">
                                        {props.event.units}
                                    </Badge> */}
                                    {renderHelperText()}
                                </div>
                            )}
                            <span className="font-weight-bold">{props.event.name}</span>
                            <div className="event-list">
                                {renderMembers()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}