import { useEffect, useState } from "react";
import { Button, Container, Table } from "reactstrap";

import apiService from "../../services/api.service";
import { MembaTeamDTO } from "../../interface/interface";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useErrorMessageContent } from "../../hooks/ErrorMessage";

export default function TeamSync() {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const { setErrorMessage, setMessageColor } = useErrorMessageContent();

  const [apiData, setApiData] = useState<MembaTeamDTO[]>([]);
  const [isLoading, setLoading] = useState(false);


  useEffect(() => {
    
  const loadApiData = () => {
    apiService.getMembaTeams().then(
      (response) => {
        //console.log("response!");
        setApiData(response.data);
        //console.log(response.data);
      })
  };

    if (!isLoading) {
      //console.log("Loading!");
      loadApiData();
      setLoading(true);
    }
  }, [isLoading]);


  const handleChange = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value.toLowerCase());
  };

  const handleImport = (membaTeamId: any) => {
    //console.log(membaTeamId);
    apiService.importTeam(membaTeamId).then(
      (response) => {
        //console.log(response)
        navigate("/team/" + response.data.id)
        setMessageColor("success");
        setErrorMessage("Hold Importeret");
        //setTeams(response.data);
      },
      error => {
        // setLoading(false);
        //props.refetch();
        //console.log(error)
        setErrorMessage("Ukendt fejl, kontakt Lars");
        if (error.response.data !== "") setErrorMessage(error.response.data);
      })
  };

  const filteredData = apiData.filter((data) => {
    return data.TeamName!.toLowerCase().match(searchInput) || data.TeamNumber!.toLowerCase().match(searchInput);
  });


  const renderTeams = filteredData.map((filteredItem, index) => {
    return (
      <React.Fragment key={index}>
        <tr>
          <th scope="row">
            <Button color="info" onClick={() => handleImport(filteredItem.TeamId)}>Import</Button>
          </th>
          <td>{filteredItem.TeamNumber}</td>
          <td>{filteredItem.TeamName}</td>
          <td>{filteredItem.TeamId}</td>
          <td>{filteredItem.TeamParentId}</td>
          <td>{filteredItem.IsGroup ? "Yes" : "No"}</td>
        </tr>
      </React.Fragment>
    );
  });

  return (
    <Container fluid="lg">
      <input
        type="search"
        placeholder="Søg"
        onChange={handleChange}
        value={searchInput} />

      <Table>
        <thead>
          <tr>
            <th></th>
            <th>TeamNumber</th>
            <th>TeamName</th>
            <th>TeamId</th>
            <th>Parent</th>
            <th>IsGroup</th>
          </tr>
        </thead>

        <tbody>

          {renderTeams}
        </tbody>

      </Table>
    </Container>
  );
}
