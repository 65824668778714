import { createContext, useContext, useState } from "react"

export type ErrorMessageContent = {
  errorMessage: string
  setErrorMessage: (message: string) => void
  messageColor: string
  setMessageColor: (color: string) => void
}

// const setErrorMessageContent = () => {

// }

export const ErrorMessageContext = createContext<ErrorMessageContent>({
  errorMessage: "", // set a default value
  setErrorMessage: () => { },
  messageColor: "", // set a default value
  setMessageColor: () => { },
})

export const useErrorMessageContent = () => useContext(ErrorMessageContext)

export function ErrorMessageContextProvider(props: any) {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [messageColor, setMessageColor] = useState<string>("danger");

  return (
    <ErrorMessageContext.Provider value={{ errorMessage, setErrorMessage, messageColor, setMessageColor }}>
      {props.children}
    </ErrorMessageContext.Provider>
  );
}