
import { CSVLink } from "react-csv";

export default function CSVLinkExporter(props) {
  return (
          /* Export Button Start */
          <CSVLink className="btn btn-warning" filename={props.filename + ".csv"} data={props.data}>
          Export to CSV
            </CSVLink>
        /* Export Button End */
  );
}