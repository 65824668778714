import { BrowserRouter } from "react-router-dom";
import NavMenu from "./components/navbar/NavMenu";
import { ErrorMessageContextProvider } from "./hooks/ErrorMessage";
import { GlobalContextProvider } from "./hooks/GlobalContent";


export default function App() {


  return (
    <GlobalContextProvider>
      <ErrorMessageContextProvider>
        <BrowserRouter>
          <NavMenu></NavMenu>
        </BrowserRouter>
      </ErrorMessageContextProvider>
    </GlobalContextProvider>
  );
}
