import { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormGroup, Input, Label } from "reactstrap";

import { useNavigate, useParams } from "react-router-dom";
import apiService from "../../services/api.service";
import { MemberEditUserLevelRequestDTO, MemberEditUserLevelResponseDTO } from "../../interface/interface";

export default function MemberEditClaims() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [userLevel, setUserLevel] = useState(0);

  const [isLoading, setLoading] = useState(true);
  const [apiData, setApiData] = useState<MemberEditUserLevelResponseDTO>();
  const loadApiData = () => {
    apiService.getMemberUserLevel(id!).then(
      (response) => {
        const memberDetails: MemberEditUserLevelResponseDTO = response.data;
        setLoading(false);
        setApiData(response.data);
        setUserLevel(memberDetails.userLevel);
        console.log(memberDetails.userLevel)
      })
  };
  useEffect(() => {
    if (isLoading) {
      loadApiData();
    }
  });


  const SubmitData = () => {
    const data: MemberEditUserLevelRequestDTO = {
      memberId: id!,
      userlevel: userLevel
    };
    apiService.setMemberEditUserLevelRequestDTO(data).then(
      () => {
        navigate("/member/details/" + apiData?.memberId)
      })
  };

  const handleUserLevel = (value: number) => {
    setUserLevel(value);
  };

  //   Reader = 0,
  //   Member = 1,
  //   Manager = 2,b nvn, 
  //   SuperUser = 3,
  //   Administrator = 4

  return (

    <Container fluid="lg">
      <h2>Edit {apiData?.name}</h2>
      <Form><FormGroup
        row
        tag="fieldset"
      >
        <legend className="col-form-label col-sm-2">
          User Level
        </legend>
        <Col sm={10}>
          <FormGroup check>
            <Input
              name="radio1"
              type="radio"
              onClick={() => handleUserLevel(0)}
            />
            {' '}
            < Label check>Reader</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              name="radio1"
              type="radio"
              onClick={() => handleUserLevel(1)}
              defaultChecked

            />
            {' '}
            < Label check>Member</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              name="radio1"
              type="radio"
              onClick={() => handleUserLevel(2)}

            />
            {' '}
            < Label check>Manager</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              name="radio1"
              type="radio"
              onClick={() => handleUserLevel(3)}

            />
            {' '}
            < Label check>SuperUser</Label>
          </FormGroup>
        </Col>
      </FormGroup>
        <Button onClick={SubmitData}>
          Submit
        </Button>
      </Form>
    </Container>


  );
}


