import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, Alert, Form, FormGroup, Input, Label } from "reactstrap";
import { MemberDTO, MemberShiftDTO, ShiftDetailsDTO } from "../../interface/interface";
import apiService from "../../services/api.service";


interface iProps {
  event?: ShiftDetailsDTO;
  isOpen: boolean;
  close: any;
}

export default function ShiftAssignMemberModal(props: iProps) {
  const { id } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [memberId, setMemberId] = useState(0);

  const close = () => props.close();

  const modalOpened = () => {
    setErrorMessage("");
    loadApiData();
  };

  //const [isLoading, setLoading] = useState(true);
  const [apiData, setApiData] = useState<MemberDTO[]>([]);
  const loadApiData = () => {
    apiService.getMembersByShiftId(props.event?.shiftId!).then(
      (response) => {
        setApiData(response.data);
        //console.log(response.data);
      })
  };


  const renderMembers = apiData?.map((item, index) => {
    return (
      <React.Fragment key={index}>
      <option value={item.memberId}>
        {item.name} ({item.membaNumber})
      </option>
      </React.Fragment>
    );
  });


  const onChangeMember = (event: any) => {
    setMemberId(event.target.value)
};

const handleSubmit = () => {
  const data: MemberShiftDTO = {
    memberId: memberId,
    shiftId: parseInt(id!),
  };
  apiService.assignMember(data).then(
    () => {
      close();
      //navigate("/member/details/" + apiData?.memberId)
    })
};
  return (
    <div>
      <Modal onOpened={modalOpened} isOpen={props.isOpen} toggle={close}>
        <ModalHeader toggle={close}>
          {/* {props.event.shiftId} */}
          {props.event?.name}
        </ModalHeader>

        {errorMessage !== "" ? <Alert color="danger">{errorMessage}</Alert> : ""}

        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleSelect">
                Vælg medhjælper
              </Label>
              
              <Input
                id="exampleSelect"
                name="select"
                type="select"
                onChange={onChangeMember}
              >
              <option value={0}>
                Vælg en medhjælper
              </option>
                {renderMembers}
              </Input>
            </FormGroup>
          </Form>

          <br></br>
          <div className="d-flex justify-content-between">
            <Button
              className="m-1"
              color="secondary"
              onClick={close}
            >
              Tilbage
            </Button>
            <div>
              <Button
              disabled={memberId === 0}
                color="success"
                className="float-right m-1"
                onClick={handleSubmit}
              >
                Tilføj
              </Button>


            </div>
          </div>
        </ModalBody>


      </Modal>
    </div>
  );
}


