import { useEffect, useState } from "react";
import { Button, Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";

import { useNavigate, useParams } from "react-router-dom";
import apiService from "../../services/api.service";
import { SectionDTO, TeamDetailsDTO } from "../../interface/interface";
import authService from "../../services/auth.service";

export default function TeamDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [apiData, setApiData] = useState<TeamDetailsDTO>();
  const loadApiData = () => {
    console.log("loadApiData");
    apiService.getTeam(parseInt(id!)).then(
      (response) => {
        setApiData(response.data);

      })
  };
  useEffect(() => {
    loadApiData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const mapSections = apiData?.sections.map((section: SectionDTO, index: number) => {
    return (
      <ListGroupItem color={section.isActive ? "success" : ""} key={index} action onClick={() => navigate("/section/details/" + section.sectionId)}>
        <h5 className="mb-1">{section.name} {section.isActive ? "(Active)" : ""}</h5>
        {/* <small className="mb-1">VoV Skranke</small> */}
      </ListGroupItem>
    );
  });

  const handleImport = () => {
    apiService.importVoV(apiData!.teamId).then(
      () => {
        //setTeams(response.data);
      })
  };

  const handleAttributes = () => {
    apiService.resetAttributes(apiData!.teamId).then(
      () => {
        //setTeams(response.data);
      })
  };

  const handleInvite = () => {
    apiService.sendInvitesToTeam(apiData!.teamId).then(
      () => {
        //setTeams(response.data);
      })
  };

  const handleImportSubTeams = () => {
    apiService.importSubTeams(apiData!.teamId).then(
      () => {
        //setTeams(response.data);
      })
  };

  const showAdminButtons = () => {
    // Return a button like this <Button color="danger" onClick={() => handleSyncMembers()}>Sync Medlemmer</Button>
    if (authService.isAdmin()) {
      return (
        <div>
          <Button onClick={() => navigate("/section/create/" + apiData?.teamId)}>Opret Vagtplan</Button>
          <Button color="danger" onClick={() => navigate("/memba/importmember/" + apiData?.teamId)}>Import Medlem</Button>
          <Button color="danger" onClick={() => handleImport()}>Import VoV</Button>
          <Button color="danger" onClick={() => handleImportSubTeams()}>Import SubTeams</Button>
          <Button color="info" onClick={() => navigate("/ShiftTypes/" + apiData?.teamId)}>ShiftTypes</Button>
          <Button color="info" onClick={() => handleAttributes()}>Require Attributes</Button>
          <Button color="info" onClick={() => handleInvite()}>Inviter</Button>
        </div>
      );
    }
    return "";

  };

  return (

    <Container fluid="lg">
      <h2>Hold: {apiData?.number} - {apiData?.name}</h2>
      {/* <Button onClick={() => navigate("/team/sync")}>Team Sync</Button> */}

      <Row xs="2">
        <Col>
          <h3>
            Vagtplan
          </h3>
          {showAdminButtons()}
          <Button color="success" onClick={() => navigate("/team/syncmembers/" + apiData?.teamId)}>Sync Medlemmer</Button>
          <ListGroup>
            {mapSections}
          </ListGroup>
        </Col>
      </Row>
    </Container>

  );
}
