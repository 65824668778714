import { useEffect, useState } from "react";
import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";

import { useNavigate, useParams } from "react-router-dom";
import apiService from "../../services/api.service";
import { ShiftDetailsDTO, ShiftEditDTO } from "../../interface/interface";
import DateTimePicker from "../../components/formcomponents/DateTimePicker";
import moment from "moment";


export default function ShiftEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());

  const [name, setName] = useState<string>();
  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSlots(parseInt(event.target.value));
  };
  const [reqDrivers, setReqDrivers] = useState<number>();
  const onChangeReqDrivers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReqDrivers(parseInt(event.target.value));
  };

  const [reqExperienced, setReqExperienced] = useState<number>();
  const onChangeReqExperienced = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReqExperienced(parseInt(event.target.value));
  };

  const [slots, setSlots] = useState<number>();
  const onChangeSlots = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSlots(parseInt(event.target.value));
  };

  const [isLoading, setLoading] = useState(true);
  const [apiData, setApiData] = useState<ShiftDetailsDTO>();
  const loadApiData = () => {
    apiService.getShift(parseInt(id!)).then(
      (response) => {
        console.log(response.data)
        setLoading(false);
        setApiData(response.data);
        setName(response.data.name);
        setSlots(response.data.slots);
        setStartTime(new Date(response.data.startTime));
        setEndTime(new Date(response.data.endTime));
        setReqDrivers(response.data.reqDrivers);
        setReqExperienced(response.data.reqExperienced);
        console.log(response.data.slots)




        // setName(response.data.name);
        // setActive(response.data.isActive);
      })
  };
  useEffect(() => {
    console.log("useEffect")
    if (isLoading) {
      loadApiData();
    }
  });


  const SubmitData = () => {
    var newStartTime = moment(startTime).format('yyyy-MM-DD HH:mm');
    var newEndTime = moment(endTime).format('yyyy-MM-DD HH:mm');

    const data: ShiftEditDTO = {
      shiftId: id!,
      name: name!,
      startTime: newStartTime,
      endTime: newEndTime,
      reqDrivers: reqDrivers!,
      reqExperienced: reqExperienced!,
      slots: slots!
    };
    apiService.updateShift(id!, data).then(
      () => {
        navigate("/shift/details/" + id);
      })
  };


  return (

    <Container fluid="lg">
      <h2>Edit Shift - {apiData?.shiftId}</h2>
      <Form>
        <FormGroup>
          <Label for="exampleName">
            Navn
          </Label>
          <Input
            type="text"
            id="formName"
            name="name"
            defaultValue={name}
            onChange={onChangeName}
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleSelectMultiTo">StartTime</Label>
          <DateTimePicker
            initDate={startTime}
            onChange={setStartTime}
            placeholderText="Start Time"
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleSelectMultiTo">EndTime</Label>
          <DateTimePicker
            initDate={endTime}
            onChange={setEndTime}
            placeholderText="End Time"
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleNumber">Required Drivers</Label>
          <Input
            id="exampleNumber"
            name="number"
            onChange={onChangeReqDrivers}
            defaultValue={reqDrivers}
            placeholder="Required Drivers"
            type="number"
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleNumber">Required Experienced</Label>
          <Input
            id="exampleNumber"
            name="number"
            onChange={onChangeReqExperienced}
            defaultValue={reqExperienced}
            placeholder="Required Experienced"
            type="number"
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleNumber">Slots</Label>
          <Input
            id="exampleNumber"
            name="number"
            onChange={onChangeSlots}
            defaultValue={slots}
            placeholder="Slots"
            type="number"
          />
        </FormGroup>
        <Button onClick={SubmitData}>
          Submit
        </Button>
      </Form>
    </Container>


  );
}
