import { Container } from "reactstrap";

export default function VOVVagtAnsvarlig() {



    return (

        <Container fluid="lg">
            <h3><b>De Vagt-Ansvarlige opgaver</b></h3>

            <h5>Åbning af VoV</h5> Få låst containerne op, opstarte pc’erne, tjekke VoV-mailen, tjekke hændelser og få læst dagbog fra dagen før. Få uddelegeret de opgaver der ligger i løbet af vagten. (Der vil blive opsat et skriv i VoV så det er nemt at overskue hvilke opgaver der ligger i løbet af en dag)
            <br></br><br></br>
            <h5>Bindeleddet til Bagvagten</h5> Som udgangspunkt er det kun bagvagten (Lene, Lars el. Janne) der kan ligge bestillinger hos stark i år. (De kan dog aftale andet med de forskellige vagtansvarlige) dette betyder at den vagtansvarlige samler bestillinger sammen og sender dem videre til bagvagten. Det er også den vagtansvarlige der melder ind til bagvagten hvis der er nogen der ikke er mødt op til sin vagt.
            <br></br><br></br>
            <h5>Overlevering</h5> Det er også den vagtansvarlige der har til opgave at få overleveret ordentligt til den næste vagtansvarlige. Det kan feks. være, hvad er der sket i løbet af dagen, er der noget man skal være opmærksom på, er der bestilt varer ved stark.?
            <br></br><br></br>
            <h5>Bindeleddet til Janne</h5> Alt omkring køretøjer går igennem Janne. Dvs. skal der laves ændringer som involverer vores leverandører af biler, polaris, gatorere, scootere, cykler eller elcykler er det den vagtansvarlige der tager kontakt til Janne.
            <br></br><br></br>
            <h5>Lukning af VoV</h5> Få lukket containere og pc’er. Få skrevet dagbog så den vagtansvarlige der går på dagen efter ved hvad der er sket og om der er noget der skal følges op på.
        </Container>
    );
}