import { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

import { useNavigate, useParams } from "react-router-dom";
import apiService from "../../services/api.service";
import { MembaImportMemberDTO, TeamDTO } from "../../interface/interface";


export default function ImportMember() {
  const navigate = useNavigate();
  const { id } = useParams();

const [membaNumber, setMembaNumber] = useState(0);

  const [isLoading, setLoading] = useState(true);
  const [apiData, setApiData] = useState<TeamDTO>();
  const loadApiData = () => {
    apiService.getTeam(parseInt(id!)).then(
      (response) => {
        setLoading(false);
        setApiData(response.data);
      })
  };

  useEffect(() => {
    if (isLoading) {
      loadApiData();
    }
    // eslint-disable-next-line
  }, []);

  const SubmitData = () => {
    if (true) {
      const data: MembaImportMemberDTO = {
        MembaNumber: membaNumber,
        TeamId: apiData!.teamId
      };
      apiService.importMember(data).then(
        (response) => {
          navigate("/member/details/" + response.data.id)
        })
    }
  };

  const onChangeMembaNumber = (event: any) => {
    setMembaNumber(event.target.value);
};

  return (

    <Container fluid="lg">
      <h2>Importer medlem til  {apiData?.number} - {apiData?.name}</h2>
      <Row xs="2"><Col>
        <Form>
          <FormGroup>
            <Label for="memberNumber">
              Member Nummer
            </Label>
            <Input
              id="memberNumber"
              name="name"
              type="text"
              onChange={onChangeMembaNumber}
            />
          </FormGroup>
          <Button onClick={SubmitData}>
            Submit
          </Button>
        </Form>
      </Col></Row>
    </Container>

  );
}
