
import { CSVLink } from "react-csv";


  // data declared to be used in table taking data from JSON file
//   const data = useMemo(() => usersData, []);
const header = ["Dato", "Start", "Slut", "Navn", "Antalfolk", "Drivers", "Erfaring", "Tidsfaktor"];
const shift = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  // Contains the column headers and table data in the required format for CSV
  let exportData = [];
  exportData.push(header)
  exportData.push(shift)

export default function SectionExporter(props) {
  return (
          /* Export Button Start */
          <CSVLink className="btn btn-warning" filename={props.filename + ".csv"} data={props.data}>
          Export to CSV
            </CSVLink>
        /* Export Button End */
  );
}