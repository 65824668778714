// import React from "react";

// type MyProps = {
//     // using `interface` is also ok
//     message: string;
// };

type MyState = {
    count: number; // like this
};

class Counter  {
    state: MyState = {
        // optional second annotation for better type inference
        count: 2,
    };

    componentDidMount() {
        this.state.count = 3;
      }

    increase(){
        this.state.count = this.state.count + 1;
        //this.setState({count : this.state.count +1});
    }

    render() {
        return (
            <div>
                Test2 {this.state.count}
            </div>
        );
    }
}


// export default new ApiService();
const counter = new Counter();
export default counter;