import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "reactstrap";

import apiService from "../../services/api.service";
import { MemberShiftDTO, ShiftDetailsDTO } from "../../interface/interface";
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ShiftAssignMemberModal from "../../components/modals/Shift.AssignMember.Modal";


export default function ShiftDetails() {
  const [eventInfoModal, setEventInfoModal] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [apiData, setApiData] = useState<ShiftDetailsDTO>();
  const loadApiData = () => {
    apiService.getShift(parseInt(id!)).then(
      (response) => {
        setLoading(false);
        setApiData(response.data);
        //console.log(response.data)
      })
  };

  useEffect(() => {
    if (isLoading) {
      loadApiData();
    }
  });

  const handleRemove = (memberId: number) => {
    const data: MemberShiftDTO = {
      memberId: memberId,
      shiftId: parseInt(id!),
    };
    apiService.removeMember(data).then(
      () => {
        loadApiData();
      })
  };

  const renderMembers = apiData?.members.map((filteredItem, index) => {
    return (
      <React.Fragment key={index}>
        <tr>
          <th scope="row">
            {/* <Button color="info" onClick={() => handleImport(filteredItem.TeamId)}>Import</Button> */}
          </th>
          <td onClick={() => navigate("/member/details/" + filteredItem.memberId)}>{filteredItem.name}</td>
          <td>{filteredItem.driver ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}</td>
          <td>{filteredItem.experienced ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}</td>
          <td>{filteredItem.firstAid ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}</td>
          <td>{!filteredItem.requireAttributes ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}</td>
          <td><Button color="danger" disabled={isLoading} onClick={() => handleRemove(filteredItem.memberId)}>Fjern</Button></td>
        </tr>
      </React.Fragment>
    );
  });


  const handleClose = () => {
    setEventInfoModal(false);
    loadApiData();
  };
  const renderModal = () => {
    return (
      <div>
        <ShiftAssignMemberModal
          event={apiData!}
          isOpen={eventInfoModal}
          close={handleClose}
        />
      </div>
    );
  };

  return (

    <Container fluid="lg">
      {renderModal()}
      <h2>{apiData?.name}</h2>
      {/* <Button disabled={isLoading} onClick={() => navigate("/manage/import/" + apiData?.sectionId)}>Importer vagtplan</Button>
      <Button onClick={() => navigate("/manage/section/edit/" + apiData?.sectionId)}>Edit</Button>
      <Button color="danger" onClick={handleDelete}>Delete</Button> */}
      <Row >
        <Col className="col-1">
          Id:
        </Col>
        <Col>
          {apiData?.shiftId}
        </Col>
      </Row>
      <Row>
        <Col className="col-1">
          Start:
        </Col>
        <Col>
          {moment(apiData?.startTime).format("DD/MM HH:mm")}
        </Col>
      </Row>
      <Row>
        <Col className="col-1">
          End:
        </Col>
        <Col>
          {moment(apiData?.endTime).format("DD/MM HH:mm")}
        </Col>
      </Row>


      <Button color="success" disabled={isLoading} onClick={() => setEventInfoModal(true)}>Tilføj Medhjælper</Button>
      <Button color="warning" disabled={isLoading} onClick={() => navigate("/shift/edit/" + apiData?.shiftId)}>Edit</Button>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>Navn</th>
            <th>23+Kørekort</th>
            <th>Erfaren</th>
            <th>Førstehjælp</th>
            <th>Udfyldt</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {renderMembers}
        </tbody>
      </Table>
    </Container>


  );
}
