import moment from "moment";
import { Card, CardBody, CardTitle, ListGroup, CardText, Button } from "reactstrap";
import { ShiftListDTO } from "../interface/interface";
import { Link } from "react-router-dom";

interface Iprops {
  shifts: ShiftListDTO[];
}


export default function ShiftList(props: Iprops) {


  const weekdays = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'];

  const renderTimerMinutter = (startTime: string, endTime: string) => {
    const a = moment(startTime);
    const b = moment(endTime);

    var duration = moment.duration(b.diff(a));
    var hours = duration.asHours();

    return (
      <small>{hours.toFixed(1)} timer</small>
    );
  };

  const mapShifts = props.shifts?.map((shift: ShiftListDTO, index: number) => {

    //const details = "Hvis du bliver syg eller på anden måde ikke kan møde op på din vagt skal du hurtigst muligt tage kontatkt til vagttelefonen. +45 2171 9302";
    const startTime = moment(shift.startTime).format("yyyyMMDDTHHmm")
    const endTime = moment(shift.endTime).format("yyyyMMDDTHHmm")
    let gCalender = "https://calendar.google.com/calendar/r/eventedit?dates=";
    gCalender += startTime + "/" + endTime;
    gCalender += "&text=" + shift.name;
    gCalender += "&location=Bøgeskoven";
    // gCalender += "&details=" + details;
    gCalender += "&sf=true";

    return (
      <Card key={index}>
        <CardBody>
          <CardTitle>
            <h5><b>{moment(shift.startTime).format("HH:mm")} - {moment(shift.endTime).format("HH:mm")} {moment(shift.endTime).format("DD/MM yyyy")}</b></h5>
            <h6>{weekdays[moment(shift.endTime).day()] + " Uge " + moment(shift.endTime).isoWeek()}</h6>
          </CardTitle>
          <CardText>
            <b>{shift.name}</b><br></br>
            {renderTimerMinutter(shift.startTime!, shift.endTime!)} -
            <small>{shift.units.toFixed(1)} Festival-timer</small><br></br>
            <Link target="_blank" to={gCalender}><Button color="info">Google Calendar</Button></Link>
          </CardText>
        </CardBody>
      </Card>
    );
  });




  return (
    <div>
      <ListGroup>
        {mapShifts}
      </ListGroup>
    </div>
  );
}