import { useEffect, useState } from "react";
import { Button, Container } from "reactstrap";
import FestivalUnits from "../../components/FestivalUnits";
import ShiftList from "../../components/Shift.List.Component";
import { useGlobalContext } from "../../hooks/GlobalContent";
import { MemberDetailsSectionDTO } from "../../interface/interface";
import ApiService from '../../services/api.service';
import { useNavigate } from "react-router-dom";

export default function MemberShifts() {
    const navigate = useNavigate();
    const { sectionId } = useGlobalContext();

    const [apiData, setApiData] = useState<MemberDetailsSectionDTO[]>();
    const loadApiData = () => {
        ApiService.getMyShifts().then(
            (response) => {
                setApiData(response.data);
                //console.log(response.data);
            })
    };

    useEffect(() => {
        loadApiData();
    }, [sectionId]); // eslint-disable-line react-hooks/exhaustive-deps

    const mapSections = apiData?.map((section: MemberDetailsSectionDTO, index: number) => {
        let sectionUnits = 0;
        section.shifts!.forEach(shift => {
            sectionUnits += shift.units;
        });


        return (
            <div key={index}>
                <h2 className="headertext">{section.sectionName} </h2>
                <div className="text-center">
                    <Button color="primary" onClick={() => navigate("/calendar/" + section.sectionId)}>Vis kalender</Button>
                </div>
                <FestivalUnits
                    units={sectionUnits}
                    sectionName={section.sectionName}
                />
                <ShiftList shifts={section.shifts!}></ShiftList>
            </div>
        );
    });

    return (
        <div>

            <Container fluid="lg">
                <div>
                    {mapSections}
                </div>
            </Container>

        </div>
    );
}