import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";

import { useNavigate, useParams } from "react-router-dom";
import apiService from "../../services/api.service";
import { SectionDetailsDTO } from "../../interface/interface";
import authService from "../../services/auth.service";


export default function SectionDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [apiData, setApiData] = useState<SectionDetailsDTO>();
  const loadApiData = () => {
    apiService.getSection(parseInt(id!)).then(
      (response) => {
        setLoading(false);
        setApiData(response.data);
      })
  };

  useEffect(() => {
    if (isLoading) {
      loadApiData();
    }
  });

  const handleDelete = () => {
    apiService.deleteSection(apiData!.sectionId).then(
      () => {
        navigate("/manage/team/" + apiData?.team.teamId);
      })
  };

  const handleCustom = () => {
    apiService.sectionCustomAction(apiData!.sectionId).then(
      () => {
        console.log("Custom");
        navigate("/calendar/" + apiData?.sectionId);
      })
  };


  const showAdminButtons = () => {
    // Return a button like this <Button color="danger" onClick={() => handleSyncMembers()}>Sync Medlemmer</Button>
    if (authService.isAdmin()) {
      return (
        <div>
          <Button disabled={isLoading} onClick={() => navigate("/import/" + apiData?.sectionId)}>Importer vagtplan</Button>
          <Button onClick={() => navigate("/section/edit/" + apiData?.sectionId)}>Edit</Button>
          <Button color="danger" onClick={handleDelete}>Delete</Button>
          <Button color="warning" onClick={() => navigate("/section/export/" + id)}>Export</Button>
          <Button color="danger" onClick={handleCustom}>Custom</Button>
        </div>
      );
    }
    return "";

  };

  return (

    <Container fluid="lg">
      <h2>Vagtplan {apiData?.name} tilhørende {apiData?.team.number} - {apiData?.team.name}</h2>
      {showAdminButtons()}
      <Button color="success" onClick={() => navigate("/members/" + apiData?.sectionId)}>Members</Button>
      <Button color="success" onClick={() => navigate("/statistics/" + apiData?.sectionId)}>Statistik</Button>
      <Button color="success" onClick={() => navigate("/calendar/" + apiData?.sectionId)}>Kalender</Button>

      <Row >
        <Col className="col-2">
          Open:
        </Col>
        <Col>
          {apiData?.openTime}
        </Col>
      </Row>
      <Row >
        <Col className="col-2">
          Close:
        </Col>
        <Col>
          {apiData?.closeTime}
        </Col>
      </Row>
      <Row>
        <Col className="col-2">
          IsOpen:
        </Col>
        <Col>
          {apiData?.isOpen ? "Yes" : "No"}
        </Col>
      </Row>



    </Container>


  );
}
