import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

import { useNavigate, useParams } from "react-router-dom";
import apiService from "../../services/api.service";
import { ShiftTypeDTO } from "../../interface/interface";


export default function ShiftTypeEdit() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState("");

    const [isLoading, setLoading] = useState(true);
    const [apiData, setApiData] = useState<ShiftTypeDTO>();
    const loadApiData = () => {
        apiService.getShiftType(parseInt(id!)).then(
            (response) => {
                //console.log(response.data)
                setLoading(false);
                setApiData(response.data);
                var data: ShiftTypeDTO = response.data;
                setName(data.name)
                setUrl(data.url)
                setDescription(data.description)
                setPriority(data.priority)
            })
    };

    useEffect(() => {
        if (isLoading) {
            loadApiData();
        }
    });

    const SubmitData = () => {
        const submitData: ShiftTypeDTO = {
            shiftTypeId: parseInt(id!),
            name: name,
            url: url,
            description: description,
            priority: priority,
            teamId: apiData!.teamId!
        };
        //console.log(submitData)
        apiService.putShiftType(submitData).then(
            () => {
                navigate("/ShiftTypes/" + apiData!.teamId!)
            })
    };

    const handleAssign = () => {
        apiService.assignShiftType(parseInt(id!)).then(
            () => {
                navigate("/ShiftTypes/" + apiData?.teamId)
            })
    };

    function handleName(event: any) {
        setName(event.target.value);
    }
    function handleUrl(event: any) {
        setUrl(event.target.value);
    }
    function handleDesc(event: any) {
        setDescription(event.target.value);
    }
    function handlePriority(event: any) {
        setPriority(event.target.value);
    }

    if (isLoading) {
        return (<Alert color="warning">Loading...</Alert>);
    }
    else {
        return (
            <Container fluid="lg">
                <h2>Rediger Vagt Type</h2>
                <Button color="success" onClick={handleAssign}>Assign</Button>
                <Row xs="2"><Col>
                    <Form>
                        <FormGroup>
                            <Label>
                                Name
                            </Label>
                            <Input
                                type="text"
                                defaultValue={name}
                                onChange={handleName}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>
                                Url
                            </Label>
                            <Input
                                type="text"
                                defaultValue={url}
                                onChange={handleUrl}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>
                                Description
                            </Label>
                            <Input
                                type="text"
                                defaultValue={description}
                                onChange={handleDesc}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>
                                Priority
                            </Label>
                            <Input
                                type="text"
                                defaultValue={priority}
                                onChange={handlePriority}
                            />
                        </FormGroup>

                        <Button onClick={SubmitData}>
                            Submit
                        </Button>
                    </Form>
                </Col></Row>
            </Container>

        );
    }
}
