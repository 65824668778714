import React, { useEffect, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  Alert,
  Container,
} from "reactstrap";
import { Routes, Route } from "react-router-dom";
import authService from "../../services/auth.service";
import LoginButton from "./LoginButton";
import { ProtectedAdminRoute, ProtectedManagerRoute, ProtectedRoute } from "./ProtectedRoute ";
import Login from "../../pages/Login";
import Calendar from "../../pages/Calendar";
import SectionCreate from "../../pages/section/Section.Create";
import TeamDetails from "../../pages/team/Team.Details";
import Members from "../../pages/member/Members";
import Profile from "../../pages/Profile";
import { useGlobalContext } from "../../hooks/GlobalContent";
import Home from "../../pages/Home";
import ManageButton from "./ManageButton";
import Unauthorized from "../../pages/Unauthorized";
import ShiftTypeCreate from "../../pages/shiftTypes/ShiftType.Create";
import Statistics from "../../pages/Statistics";
import ShiftDetails from "../../pages/shift/Shift.Details";
import MemberDetails from "../../pages/member/Member.Details";
import { useErrorMessageContent } from "../../hooks/ErrorMessage";
import SectionDetails from "../../pages/section/Section.Details";
import SectionEdit from "../../pages/section/Section.Edit";
import SectionImport from "../../pages/section/Section.Import";
import MemberEdit from "../../pages/member/Member.Edit";
import MemberLog from "../../pages/member/Member.Log";
import MemberShifts from "../../pages/member/Member.Shifts";
import MemberAttributes from "../../pages/member/MemberAttributes";
import Teams from "../../pages/team/Teams";
import TeamSync from "../../pages/team/TeamSync";
import ImportMember from "../../pages/memba/Import.Member";
import ShiftTypes from "../../pages/shiftTypes/ShiftTypes";
import ShiftTypeCRUD from "../../pages/shiftTypes/ShiftType";
import ShiftTypeEdit from "../../pages/shiftTypes/ShiftType.Edit";
import VOVVagtAnsvarlig from "../../pages/shiftTypes/descriptions/VOV.VagtAnsvarlig";
import SectionExport from "../../pages/section/Section.Export";
import MemberEditClaims from "../../pages/member/Member.Edit.Claims";
import TeamSelector from "./TeamSelector";
import TeamSyncMembers from "../../pages/team/Team.SyncMembers";
import ShiftEdit from "../../pages/shift/Shift.Edit";

export default function NavMenu() {
  const { setUserName } = useGlobalContext();
  const { errorMessage, messageColor } = useErrorMessageContent();
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    //console.log("Effect!!!");
  }, [setUserName]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderNavLinks = () => {
    if (authService.isAuth())
      return (
        <React.Fragment>

          {/* <NavLink href="/calendar" className="text-dark">
            Kalender
          </NavLink> */}

          <NavLink href="/shifts" className="text-dark">
            Mine Vagter
          </NavLink>

          {/* <SectionSelector></SectionSelector> */}
        </React.Fragment>
      );
    return;
  };

  const refresh = (isAuth: boolean) => {
    //setIsAuth(isAuth);
  }

  //console.log(authService.isAuth());
  return (
    <div>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
        container
        light
      >
        <NavbarBrand href="/">SmukVagt</NavbarBrand>
        <NavbarToggler onClick={toggle} className="mr-2" />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            {renderNavLinks()}
            <TeamSelector></TeamSelector>
            <ManageButton></ManageButton>


          </Nav>
          <LoginButton refresh={refresh}></LoginButton>
        </Collapse>
      </Navbar>


      {/* {counter.render()} */}
      {errorMessage !== "" ?
        <Container>
          <Alert color={messageColor}>
            {errorMessage}
          </Alert>
        </Container>
        : ""
      }

      <Routes>
        {/* Public */}
        <Route path="/login" element={<Login />} />
        <Route path="/unauthorized" element={<Unauthorized />} />

        {/* Authorized */}
        <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/calendar/:id" element={<ProtectedRoute><Calendar /></ProtectedRoute>} />
        <Route path="/attributes" element={<ProtectedRoute><MemberAttributes /></ProtectedRoute>} />
        <Route path="/shifts" element={<ProtectedRoute><MemberShifts /></ProtectedRoute>} />
        <Route path="/info/vagtansvarlig" element={<ProtectedRoute><VOVVagtAnsvarlig /></ProtectedRoute>} />

        {/* Manager */}
        <Route path="/members/:id" element={<ProtectedManagerRoute><Members /></ProtectedManagerRoute>} />
        <Route path="/statistics/:id" element={<ProtectedManagerRoute><Statistics /></ProtectedManagerRoute>} />
        <Route path="/shift/details/:id" element={<ProtectedManagerRoute><ShiftDetails /></ProtectedManagerRoute>} />
        <Route path="/shift/edit/:id" element={<ProtectedManagerRoute><ShiftEdit /></ProtectedManagerRoute>} />
        <Route path="/member/details/:id" element={<ProtectedManagerRoute><MemberDetails /></ProtectedManagerRoute>} />
        <Route path="/member/edit/:id" element={<ProtectedManagerRoute><MemberEdit /></ProtectedManagerRoute>} />
        <Route path="/team/:id" element={<ProtectedManagerRoute><TeamDetails /></ProtectedManagerRoute>} />
        <Route path="/team/syncmembers/:id" element={<ProtectedManagerRoute><TeamSyncMembers /></ProtectedManagerRoute>} />
        <Route path="/section/details/:id" element={<ProtectedManagerRoute><SectionDetails /></ProtectedManagerRoute>} />

        {/* Admin */}
        <Route path="/team" element={<ProtectedAdminRoute><Teams /></ProtectedAdminRoute>} />
        <Route path="/member/log" element={<ProtectedAdminRoute><MemberLog /></ProtectedAdminRoute>} />
        <Route path="/memba/importmember/:id" element={<ProtectedAdminRoute><ImportMember /></ProtectedAdminRoute>} />
        <Route path="/member/editclaims/:id" element={<ProtectedAdminRoute><MemberEditClaims /></ProtectedAdminRoute>} />
        <Route path="/team/sync" element={<ProtectedAdminRoute><TeamSync /></ProtectedAdminRoute>} />
        <Route path="/section/create/:id" element={<ProtectedAdminRoute><SectionCreate /></ProtectedAdminRoute>} />
        <Route path="/section/edit/:id" element={<ProtectedAdminRoute><SectionEdit /></ProtectedAdminRoute>} />
        <Route path="/section/export/:id" element={<ProtectedAdminRoute><SectionExport /></ProtectedAdminRoute>} />
        <Route path="/import/:id" element={<ProtectedAdminRoute><SectionImport /></ProtectedAdminRoute>} />
        <Route path="/ShiftTypes/:id" element={<ProtectedAdminRoute><ShiftTypes /></ProtectedAdminRoute>} />
        <Route path="/ShiftType/:crud/:id" element={<ProtectedAdminRoute><ShiftTypeCRUD /></ProtectedAdminRoute>} />
        <Route path="/ShiftType/Create/:id" element={<ProtectedAdminRoute><ShiftTypeCreate /></ProtectedAdminRoute>} />
        <Route path="/ShiftType/Edit/:id" element={<ProtectedAdminRoute><ShiftTypeEdit /></ProtectedAdminRoute>} />

        {/* Test */}
        <Route path="/lars" element={<ProtectedAdminRoute><Profile /></ProtectedAdminRoute>} />
        <Route path="/profile" element={<ProtectedAdminRoute><Profile /></ProtectedAdminRoute>} />

      </Routes>
    </div >
  );
}
