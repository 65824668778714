import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, Table } from "reactstrap";
import { ShiftTypeDTO } from "../../interface/interface";
import apiService from "../../services/api.service";

export default function ShiftTypes() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [apiData, setApiData] = useState<ShiftTypeDTO[]>([]);
  const loadApiData = () => {
    apiService.getShiftTypesFromSectionId(parseInt(id!)).then(
      (response) => {
        setApiData(response.data);
        //console.log(response.data);
      })
  };


  useEffect(() => {
    loadApiData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  
  const renderApiData = apiData?.map((item, index) => {
    return (
      <React.Fragment key={index}>
        <tr onClick={() => navigate("/ShiftType/Edit/" + item.shiftTypeId)}>
          <td>{item.name}</td>
          <td>{item.priority}</td>
          <td></td>
        </tr>
      </React.Fragment>
    );
  });

  return (
    <Container fluid="lg">
      <h2>ShiftTypes</h2>
      <Button color="success" onClick={() => navigate("/ShiftType/Create/" + id)}>Opret</Button>
      <Table hover>
        <thead>
          <tr>
            <th>Navn</th>
            <th>Prioritet</th>
          </tr>
        </thead>
        <tbody>
          {renderApiData}
        </tbody>
      </Table>


    </Container>

  );
}
