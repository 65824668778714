import axios from "axios";
import { MembaImportMemberDTO, MemberAttributesDTO, MemberEditDTO, MemberEditUserLevelRequestDTO, MemberShiftDTO, SectionCreateDTO, SectionEditDTO, ShiftEditDTO, ShiftExchangeDTO, ShiftTypeCreateDTO, ShiftTypeDTO } from "../interface/interface";
import authHeader from "./auth-header"


let API_URL_development = "https://localhost:7217/api/";
const API_URL_production = "https://smukvagtdb.azurewebsites.net/api/";

// API_URL_development = "https://smukvagtdb.azurewebsites.net/api/"; // TODO DEBUG

export const API_URL = process.env.NODE_ENV === "production" ? API_URL_production : API_URL_development;

export const ENDPOINTS = {
  emailController: "email",
  teamController: "team",
  membaController: "memba",
  memberController: "member",
  membersController: "members",
  memberShiftController: "membershift",
  memberLogsController: "memberlogs",
  sectionController: "sections",
  shiftTypesController: "shifttypes",
  shiftController: "shifts",
  acceptShift: "shiftcrew",
  shiftcrew: "shiftcrew",
  getMembersShifts: "member/shifts",
  vovController: "vov2022",
  statisticsController: "statistics",
};

class ApiService {

  /*
  * Memba
  */

  importMember(data: MembaImportMemberDTO) {
    return axios.post(API_URL + ENDPOINTS.membaController + "/ImportMember",
      data,
      { headers: authHeader() });
  }

  //return axios.get(API_URL + 'user', { headers: authHeader() });
  getShifts(id: string) {
    return axios.get(API_URL + ENDPOINTS.memberController + "/section/" + id, { headers: authHeader() });
  }

  getTeams() {
    return axios.get(API_URL + ENDPOINTS.teamController, { headers: authHeader() });
  }

  getTeam(id: number) {
    return axios.get(API_URL + ENDPOINTS.teamController + "/" + id, { headers: authHeader() });
  }

  getMembaTeams() {
    return axios.get(API_URL + ENDPOINTS.membaController + "/teams", { headers: authHeader() });
  }

  importTeam(id: number) {
    return axios.post(API_URL + ENDPOINTS.membaController + "/ImportTeam/" + id,
      null, // TODO
      { headers: authHeader() });
  }

  syncMembers(id: number) {
    return axios.post(API_URL + ENDPOINTS.membaController + "/SyncMembers/" + id,
      null, // TODO
      { headers: authHeader() });
  }

  getShift(id: number) {
    return axios.get(API_URL + ENDPOINTS.shiftController + "/" + id, { headers: authHeader() });
  }

  getMembersShifts(sectionId: string) {
    return axios.get(API_URL + ENDPOINTS.getMembersShifts + "/" + sectionId, { headers: authHeader() });
  }

  getMyShifts() {
    // return axios.get(API_URL + ENDPOINTS.getMembersShifts + "/" + sectionId, { headers: authHeader() });
    return axios.get(API_URL + ENDPOINTS.memberController + "/MyShifts/", { headers: authHeader() });
  }

  /*
  * Members
  */
  getMembers(sectionId: string) {
    return axios.get(API_URL + ENDPOINTS.memberController + "/members/" + sectionId, { headers: authHeader() });
  }
  getMemberUserLevel(memberId: string) {
    return axios.get(API_URL + ENDPOINTS.membersController + "/userlevel/" + memberId, { headers: authHeader() });
  }

  setMemberEditUserLevelRequestDTO(data: MemberEditUserLevelRequestDTO) {
    return axios.post(API_URL + ENDPOINTS.membersController + "/userlevel/",
      data,
      { headers: authHeader() });
  }

  getMembersByShiftId(id: number) {
    return axios.get(API_URL + ENDPOINTS.membersController + "/shift/" + id, { headers: authHeader() });
  }

  getMemberLogs() {
    return axios.get(API_URL + ENDPOINTS.memberLogsController, { headers: authHeader() });
  }

  getMember(sectionId: string) {
    return axios.get(API_URL + ENDPOINTS.membersController + "/" + sectionId, { headers: authHeader() });
  }

  editMember(data: MemberEditDTO) {
    return axios.put(API_URL + ENDPOINTS.membersController + "/" + data.memberId,
      data,
      { headers: authHeader() });
  }


  deleteMember(id: number) {
    return axios.delete(API_URL + ENDPOINTS.membersController + "/" + id,
      { headers: authHeader() });
  }

  assignMember(data: MemberShiftDTO) {
    return axios.post(API_URL + ENDPOINTS.memberShiftController + "/AssignMember",
      data,
      { headers: authHeader() });
  }

  removeMember(data: MemberShiftDTO) {
    return axios.post(API_URL + ENDPOINTS.memberShiftController + "/RemoveMember",
      data,
      { headers: authHeader() });
  }
  /*
  * Member Shifts
  */
  acceptShift(shiftId: number) {
    return axios.post(API_URL + ENDPOINTS.memberController + "/acceptshift/",
      { ShiftId: shiftId },
      { headers: authHeader() });
  }

  removeShift(id: number) {
    return axios.delete(API_URL + ENDPOINTS.memberController + "/removeShift/" + id, { headers: authHeader() });
  }

  exchangeShift(data: ShiftExchangeDTO) {
    return axios.put(API_URL + ENDPOINTS.memberController + "/exchangeShift/",
      data,
      { headers: authHeader() });
  }

  setMemberAttributes(attributes: MemberAttributesDTO) {
    return axios.put(API_URL + ENDPOINTS.memberController + "/1",
      attributes,
      { headers: authHeader() });
  }

  resetAttributes(id: number) {
    return axios.get(API_URL + ENDPOINTS.teamController + "/resetattributes/" + id,
      { headers: authHeader() });
  }


  sendInvitesToMember(id: number) {
    return axios.get(API_URL + ENDPOINTS.emailController + "/SendInvitesToMember/" + id,
      { headers: authHeader() });
  }

  /*
  * Team
  */
  sendInvitesToTeam(id: number) {
    return axios.get(API_URL + ENDPOINTS.emailController + "/SendInvitesToTeam/" + id,
      { headers: authHeader() });
  }

  importSubTeams(id: number) {
    return axios.post(API_URL + ENDPOINTS.membaController + "/ImportSubTeams/" + id,
      null, // TODO
      { headers: authHeader() });
  }


  /*
  * ShiftTypes
  */
  getShiftTypesFromSectionId(id: number) {
    return axios.get(API_URL + ENDPOINTS.shiftTypesController + "/SectionId/" + id, { headers: authHeader() });
  }

  getShiftType(id: number) {
    return axios.get(API_URL + ENDPOINTS.shiftTypesController + "/" + id, { headers: authHeader() });
  }

  assignShiftType(id: number) {
    return axios.get(API_URL + ENDPOINTS.shiftTypesController + "/AssignShiftType/" + id, { headers: authHeader() });
  }

  createShiftType(section: ShiftTypeCreateDTO) {
    return axios.post(API_URL + ENDPOINTS.shiftTypesController,
      section,
      { headers: authHeader() });
  }

  putShiftType(data: ShiftTypeDTO) {
    return axios.put(API_URL + ENDPOINTS.shiftTypesController + "/" + data.shiftTypeId,
      data,
      { headers: authHeader() });
  }
  /*
  * Sections
  */
  createSection(section: SectionCreateDTO) {
    return axios.post(API_URL + ENDPOINTS.sectionController,
      section,
      { headers: authHeader() });
  }

  updateSection(section: SectionEditDTO) {
    return axios.put(API_URL + ENDPOINTS.sectionController,
      section,
      { headers: authHeader() });
  }

  deleteSection(id: string) {
    return axios.delete(API_URL + ENDPOINTS.sectionController + "/" + id,
      { headers: authHeader() });
  }

  sectionCustomAction(id: string) {
    return axios.get(API_URL + ENDPOINTS.sectionController + "/customaction/" + id, { headers: authHeader() });
  }

  getSection(id: number) {
    return axios.get(API_URL + ENDPOINTS.sectionController + "/" + id, { headers: authHeader() });
  }

  getSections() {
    return axios.get(API_URL + ENDPOINTS.memberController + "/sections/", { headers: authHeader() });
  }

  /*
  * Shifts
  */
  updateShift(id: string, data: ShiftEditDTO) {
    return axios.put(API_URL + ENDPOINTS.shiftController + "/" + id,
      data,
      { headers: authHeader() });
  }


  getMyTeams() {
    return axios.get(API_URL + ENDPOINTS.teamController + "/GetMyTeams/", { headers: authHeader() });
  }

  getSectionExportData(id: number) {
    return axios.get(API_URL + ENDPOINTS.sectionController + "/exportsection/" + id, { headers: authHeader() });
  }


  importShifts(id: string, selectedFile: any) {
    var formData = new FormData();
    formData.append("file", selectedFile);

    return axios.post(API_URL + ENDPOINTS.shiftController + "/import/" + id,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
  }


  getStatistics(id: number) {
    return axios.get(API_URL + ENDPOINTS.statisticsController + "/" + id,
      { headers: authHeader() });
  }
  // AcceptShift() {
  //   return localStorage.getItem('token');
  // }

  // TODO Remove
  importVoV(id: number) {
    return axios.get(API_URL + ENDPOINTS.vovController + "/Teams/" + id,
      { headers: authHeader() });
  }
  // TODO
  // importVoV() {
  //   return axios.get(API_URL + "User/Admins/",
  //   { headers: authHeader() });
  // }

}

// export default new ApiService();
const apiService = new ApiService();
export default apiService;