import { useEffect, useState } from "react";
import { Container, Table } from "reactstrap";

import { useNavigate, useParams } from "react-router-dom";
import apiService from "../../services/api.service";
import { SectionExportDTO } from "../../interface/interface";
import SectionExporter from "./Section.Exporter";
import React from "react";


export default function SectionExport() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [apiData, setApiData] = useState<SectionExportDTO>();
    const loadApiData = () => {
        apiService.getSectionExportData(parseInt(id!)).then(
            (response) => {
                setLoading(false);
                setApiData(response.data);
            })
    };

    useEffect(() => {
        if (isLoading) {
            loadApiData();
        }
    });

    let exportData: string[][] = [];
    const header = ["Dato", "Start", "Slut", "Navn", "Antalfolk", "Drivers", "Erfaring", "Tidsfaktor"];
    exportData.push(header);

    const renderApiData = apiData?.shifts.map((filteredItem, index) => {

        let csvShift: string[] = [filteredItem.date!, filteredItem.startTime!, filteredItem.endTime!, filteredItem.name!, filteredItem.slots!, filteredItem.drivers!, filteredItem.experienced!, filteredItem.timeFactor!];

        filteredItem.members.forEach(element => {
            csvShift.push(element.email!);
        });

        exportData.push(csvShift);

        return (
            <React.Fragment key={index}>
                <tr onClick={() => navigate("/member/details/" + filteredItem.name)}>
                    <th scope="row">
                    </th>
                    <td>{filteredItem.date}</td>
                    <td>{filteredItem.startTime}</td>
                    <td>{filteredItem.endTime}</td>
                    <td>{filteredItem.name}</td>
                    <td>{filteredItem.slots}</td>
                    <td>{filteredItem.drivers}</td>
                    <td>{filteredItem.experienced}</td>
                    <td>{filteredItem.timeFactor}</td>
                    <td>
                    {filteredItem.members.map(member => (

                        <div>{member.email}
                        </div>
                    ))}
                    </td>
                </tr>
            </React.Fragment>
        );
    });

    return (

        <Container fluid="lg">
            <h2>Export section</h2>
            <h4>{apiData?.teamName} - {apiData?.name}</h4>


            <SectionExporter data={exportData} filename={apiData?.teamName + "_" + apiData?.name}></SectionExporter>
            <Table hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Dato</th>
                        <th>Start</th>
                        <th>Slut</th>
                        <th>Navn</th>
                        <th>Antalfolk</th>
                        <th>Drivers</th>
                        <th>Erfaring</th>
                        <th>Tidsfaktor</th>
                        <th>Members</th>
                    </tr>
                </thead>
                <tbody>
                    {renderApiData}
                </tbody>
            </Table>

        </Container>


    );
}
