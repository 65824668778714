import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Container, Form, FormGroup, Input, Label } from "reactstrap";

import { useNavigate, useParams } from "react-router-dom";
import apiService from "../../services/api.service";
import { MemberDetailsDTO, MemberDetailsSectionDTO } from "../../interface/interface";
import ShiftList from "../../components/Shift.List.Component";
import FestivalUnits from "../../components/FestivalUnits";


export default function MemberDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [rSelected, setRSelected] = useState(1);

  const [isLoading, setLoading] = useState(true);
  const [apiData, setApiData] = useState<MemberDetailsDTO>();
  const loadApiData = () => {
    apiService.getMember(id!).then(
      (response) => {
        setLoading(false);
        setApiData(response.data);
      })
  };

  useEffect(() => {
    if (isLoading) {
      loadApiData();
    }
  });

  const handleInvite = () => {
    apiService.sendInvitesToMember(apiData!.memberId).then(
      () => {
        //setTeams(response.data);
      })
  };

  const handleDelete = () => {
    apiService.deleteMember(apiData!.memberId).then(
      () => {
        navigate("/members");
      })
  };

  const mapSections = apiData?.sections.map((section: MemberDetailsSectionDTO, index: number) => {
    let sectionUnits = 0;
    section.shifts!.forEach(shift => {
      sectionUnits += shift.units;
    });


    return (
      <div key={index}>
        <h2 className="headertext">{section.sectionName} </h2>
        <FestivalUnits
          units={sectionUnits}
          sectionName={section.sectionName}
        />
        <ShiftList shifts={section.shifts!}></ShiftList>
      </div>
    );
  });

  return (

    <Container fluid="lg">
      <h2>{apiData?.membaNumber} - {apiData?.name} </h2>
      <div>
        <ButtonGroup>
          <Button
            color="primary"
            outline
            onClick={() => setRSelected(1)}
            active={rSelected === 1}
          >
            Vagter
          </Button>
          <Button
            color="primary"
            outline
            onClick={() => setRSelected(2)}
            active={rSelected === 2}
          >
            Info
          </Button>

          <Button
            color="primary"
            outline
            onClick={() => handleInvite()}
            active={rSelected === 3}
          >
            Inviter
          </Button>

          <Button
            color="danger"

            onClick={() => handleDelete()}
            active={rSelected === 3}
          >
            Slet Medhjælper
          </Button>

        </ButtonGroup>
      </div>
      {/* <Button disabled={isLoading} onClick={() => navigate("/import/" + apiData?.sectionId)}>Importer vagtplan</Button> */}
      {/* <Button color="danger" onClick={handleDelete}>Delete</Button> */}

      {rSelected === 1 ?
        <div>
          {mapSections}
        </div>
        : ""}

      {rSelected === 2 ?
        <div>
          <Button onClick={() => navigate("/member/edit/" + apiData?.memberId)}>Edit</Button>
          <Button onClick={() => navigate("/member/editclaims/" + apiData?.memberId)}>Claims</Button>
          {/* <Link target="_blank" to={"https://memba.dk/member/stamdata/?id=" + apiData?.membaId!}><Button color="info">Memba Profil </Button></Link> */}
          <p>Phone: {apiData?.phoneNumber}</p>
          <Form>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={apiData?.driver}
                disabled={true}
              />
              {' '}
              <Label check>
                Kørekort
              </Label>
            </FormGroup>

            <FormGroup check>
              <Input
                disabled={true}
                type="checkbox"
                checked={apiData?.experienced}
              />
              {' '}
              <Label check>
                Erfaren
              </Label>
            </FormGroup>

            <FormGroup check>
              <Input
                disabled={true}
                type="checkbox"
                checked={apiData?.firstAid}
              />
              {' '}
              <Label check>
                Førstehjælp
              </Label>
            </FormGroup>

            <FormGroup check>
              <Input
                disabled={true}
                type="checkbox"
                checked={apiData?.requireAttributes}
              />
              {' '}
              <Label check>
                Kræv Attributter
              </Label>
            </FormGroup>
          </Form>
        </div> : ""}


    </Container>

  );
}
