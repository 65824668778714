import { useEffect, useState } from "react";
import { Alert, Container, Spinner, Table } from "reactstrap";

import { useParams } from "react-router-dom";
import apiService from "../../services/api.service";
import { TeamSyncMembersDTO } from "../../interface/interface";
import React from "react";

export default function TeamSyncMembers() {
    const { id } = useParams();

    const [isLoading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [apiData, setApiData] = useState<TeamSyncMembersDTO>();
    const loadApiData = () => {
        console.log("loadApiData");
        apiService.syncMembers(parseInt(id!)).then(
            (response) => {
                setLoading(false);
                setApiData(response.data);
                console.log(response.data);
            },
            error => {
                setLoading(false);
                if (error.message !== "") setErrorMessage(error.message);
                if (error.response.data !== "") setErrorMessage(error.response.data);
            })
    };


    useEffect(() => {
        if (isLoading) {
            loadApiData();
        }
    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps


    const renderMembersAdded = apiData?.membersAdded.map((memberItem, index) => {
        return (
            <React.Fragment key={index}>
                <tr>
                    <td>{memberItem.membaNumber}</td>
                    <td>{memberItem.name}</td>
                </tr>
            </React.Fragment>
        );
    });


    const renderMembersUpdated = apiData?.membersUpdated.map((memberItem, index) => {
        return (
            <React.Fragment key={index}>
                <tr>
                    <td>{memberItem.membaNumber}</td>
                    <td>{memberItem.name}</td>
                </tr>
            </React.Fragment>
        );
    });
    const renderTable = (renderMembersAdded: any) => {
        return (
            <div>

                <Table hover>
                    <thead>
                        <tr>
                            <th>Memba Nummer</th>
                            <th>Navn</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderMembersAdded}
                    </tbody>
                </Table>
            </div>
        );
    };

    if (errorMessage !== "") {
        return (
            <Container>
                <Alert color="danger">{errorMessage}</Alert>
            </Container>
        );
    }
    else if (isLoading) {
        return (
            <Container>
                <Alert color="warning">
                    <Spinner>
                        Loading...
                    </Spinner>
                    <br></br>Importing members</Alert>
            </Container >
        );
    }
    else {
        return (
            <Container>
                {/* <h2>Importing members</h2> */}
                {(apiData?.membersAdded.length === 0 && apiData?.membersUpdated.length === 0) ? <Alert color="success"> Nothing to import</Alert> : ""}
                {apiData?.membersAdded.length === 0 ? "" : <div><Alert color="success"> Imported {apiData?.membersAdded.length} member(s)</Alert> {renderTable(renderMembersAdded)}</div>}
                {apiData?.membersUpdated.length === 0 ? "" : <div><Alert color="warning">Updated {apiData?.membersUpdated.length} member(s)</Alert> {renderTable(renderMembersUpdated)}</div>}

            </Container>
        );
    }
}
