import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from "reactstrap";
import authService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { TeamDTO } from "../../interface/interface";
import apiService from "../../services/api.service";

export default function TeamSelector() {

  const navigate = useNavigate();
  const [apiData, setApiData] = useState<TeamDTO[]>([]);
  const loadApiData = () => {
    apiService.getMyTeams().then(
      (response) => {
        if (response.data.length > 0) {
          console.log(response.data);
          setApiData(response.data);
          // if (sectionId === "") {
          //   // setSectionId(response.data[0].sectionId)
          //   selectSection(response.data[0])
          // }
        }
      })
  };


  useEffect(() => {
    if (apiData.length === 0) {
      loadApiData();
    }
  });

  const selectSection = (team: TeamDTO) => {
    console.log("selectSection: " + team.name);
    navigate("/team/" + team.teamId);
    //setCurrentSection(section);
    // setSectionId(section.sectionId);
    // setSectionName(section.team.name + " - " + section.name);
    // setContext({participantId: section.name})

    //console.log(currentSection)
    // if (props.onSectionChange !== undefined) {
    //   props.onSectionChange(section.sectionId);
    // }
  };

  const renderButton = () => {

    if (authService.isManager()) {
      //If apidata is empty, return nothing
      if (apiData.length === 0) {
        return "";
      }
      // If is length 1, return the only team
      if (apiData.length === 1) {
        const teamURL = "/team/" + apiData[0].teamId;
        return (
          <NavLink href={teamURL} className="text-dark" >
            {apiData[0].name}
          </NavLink >
        );
      }

      return (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle className="text-dark" nav caret>
            {apiData.length === 0 ? "Loading..." : "Team"}
          </DropdownToggle>
          <DropdownMenu end>
            {apiData.map((item, idx) => (
              <DropdownItem
                key={idx}
                onClick={() => {
                  selectSection(item);
                }}
              >
                {item.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  };


  return (
    <div>{renderButton()}</div>


  );
}
